import { ShopifyCategory } from '../types';

const getHandle = (shopifyCategory: ShopifyCategory) => {
  const shopifyCategoryItems = shopifyCategory.shopify_category?.items;

  if (Array.isArray(shopifyCategoryItems) && shopifyCategoryItems.length > 0) {
    return shopifyCategoryItems[0].handle;
  }
};

export default {
  getHandle,
};
